
import React from 'react';

const NavigationContext = React.createContext();
const NavigationProvider = ({ children, navigation }) => {
    return (
      <NavigationContext.Provider value={navigation}>
        {children}
      </NavigationContext.Provider>
    );
  };
  

const useNavigation = () => {
    const navigation = React.useContext(NavigationContext);
    if (!navigation) {
      throw new Error('useNavigation must be used within a NavigationProvider');
    }
    return navigation;
  };

export { NavigationProvider, useNavigation };