import { makeStyles, tokens } from '@fluentui/react-components';
import { Call28Filled, Call28Regular, Chat28Filled, Chat28Regular, Person28Filled, Person28Regular, bundleIcon } from '@fluentui/react-icons';
import React from 'react';
import message from './assets/message.svg';
import phone from './assets/phone.svg';
import TabItem from './TabItem';

const useStyles = makeStyles({
	tabsContainer: {
		display: 'flex',
		maxWidth: '76px',
		minWidth: '68px',
		height: 'auto',
		alignItems: 'center',
		flexDirection: 'column',
		background: tokens.colorTransparentBackground,
		//boxShadow: tokens.shadow28,
		paddingTop: tokens.spacingVerticalXXXL,
		paddingRight: tokens.spacingHorizontalXS,
		//background: 'linear-gradient(90deg, rgba(255,255,255,1) 90%, rgba(204,204,204,1) 99%, rgba(173,173,173,1) 100%)'
	},
});

const tabData = [
	{
		icon: bundleIcon(Chat28Filled, Chat28Regular),
		src: message,
		title: 'Chat',
		to: '/chat',
	},
	{
		
		icon: bundleIcon(Call28Filled, Call28Regular),
		//icon: <Call28Regular />,
		src: phone,
		title: 'Calls',
		to: '/calls',
	},
	{
		
		icon: bundleIcon(Person28Filled, Person28Regular),
		//icon: <Person28Regular />,
		src: phone,
		title: 'Contacts',
		to: '/contacts',
	},
	{
		
		icon: bundleIcon(Call28Filled, Call28Regular),
		//icon: <Call28Regular />,
		src: phone,
		title: 'In Call',
		to: '/in-call',
	},
];

const Tabs = () => {
	const styles = useStyles();
	const [refresh, setRefresh] = React.useState(false);
	const onNavigated = (to) => {
		setRefresh((e) => !e);
	}
	
	return (
		<div className={styles.tabsContainer}>
			{tabData.map((tab, index) => (
				<TabItem key={index} Icon={tab.icon} src={tab.src} alt={tab.title} to={tab.to} navigated={onNavigated} />
			))}
		</div>
	);
};

export default Tabs;
