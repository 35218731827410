import {
  Caption2,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components';
import React from 'react';
import { useNavigation } from './providers/NavigationProvider';

const useStyles = makeStyles({
	tabsContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%',
		textAlign: 'center',
		textDecoration: 'none',
		borderLeft: `3px solid transparent`,
		boxSizing: 'border-box',
		':visited': {
			color: tokens.colorNeutralForeground1,
		},
		':hover': {
			borderLeft: `3px solid ${tokens.colorBrandBackground}`,
			backgroundColor: tokens.colorNeutralBackground1Hover,
		},
	},

	tabItem: {
		padding: tokens.spacingHorizontalM,
    color: tokens.colorNeutralForeground3,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},

	activeContainer: {
		borderLeft: `3px solid ${tokens.colorBrandBackground}`,
	},

	active: {
		color: tokens.colorBrandForeground1,
	},
});

const TabItem = ({ Icon, src, alt, to, navigated }) => {
	const styles = useStyles();
	const navigate = useNavigation();
	const isActive = window.location.pathname.startsWith(to);

	const merged = mergeClasses(
		styles.tabsContainer,
		isActive && styles.activeContainer,
	);
	const merged2 = mergeClasses(styles.tabItem, isActive && styles.active);

	const onClick = (e) => {
		e.preventDefault();
		const to = e.currentTarget.getAttribute('href');
		navigate(to);
		navigated && navigated(to);
	};


	return (
		<a href={to} onClick={onClick}  className={merged}>
			<div className={merged2}>
				{isActive && <Icon filled />}
				{!isActive && <Icon />}
				<Caption2>{alt}</Caption2>
			</div>
		</a>
	);
};

export default TabItem;
