import { FluentProvider, IdPrefixProvider, tokens } from "@fluentui/react-components";
import React from "react";
import Tabs from "./Tabs";
import { useTheme } from './theme/theme';
import { NavigationProvider } from "./providers/NavigationProvider";

const theme = useTheme();

const Root = (props) => {
	const { navigation } = props;
  return (
    
    <IdPrefixProvider value="cc-toolbar">
      <FluentProvider theme={theme.lightTheme} style={{background: tokens.colorTransparentBackground}}>
        <NavigationProvider navigation={navigation}>
          <Tabs />
        </NavigationProvider>
      </FluentProvider>
    </IdPrefixProvider>
  );
}

export default Root;
